<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useClientCenterStore } from '@/store/clientCenterStore';
import { storeToRefs } from 'pinia';
import UserActiveComponent from "../components/ClientCenter/UserActiveComponent.vue"
import { useUtils } from '@/utils/index'
import EditTagDialog from './TagSet/EditTagDialog.vue'
import AddTagDialog from './TagSet/AddTagDialog.vue'
import dayjs from 'dayjs'

const utils = useUtils()
const clientCenterStore = useClientCenterStore()
const { selectedUser } = storeToRefs(clientCenterStore)
const addTagDialogVisible = ref(false)
const editTagDialogVisible = ref(false)

const userTag = ref([])
const addTag = () => {
    addTagDialogVisible.value = true
}
const closeAddTagDialog = async () => {
    addTagDialogVisible.value = false
    await getMemberUserTag()
}
const closeEditTagDialog = async () => {
    editTagDialogVisible.value = false
    await getMemberUserTag()
}
const editTag = () => {
    editTagDialogVisible.value = true
}
const getMemberUserTag = async () => {
    const res = await utils.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Enterprise/IndividualMemberUserTag/${selectedUser.value.id}`)
    userTag.value = res.data
}
onMounted(async () => {
    await getMemberUserTag()
})
watch(selectedUser, async () => {
    await getMemberUserTag()
})

</script>
<template>
    <div class="d-flex flex-column">
        <UserActiveComponent />
        <el-divider class="tagDivide" />
        <div class="d-flex justify-content-between align-items-center px-2 ">
            <span>{{ $t('QACenter.TagSet.IsChoice') }}</span>
            <div class="d-flex">
                <el-button link
                           @click="addTag">{{ $t('QACenter.TagSet.Add') }}</el-button>
                <el-button link
                           @click="editTag">{{ $t('QACenter.TagSet.Edit') }}</el-button>
            </div>
        </div>
        <div class="d-flex flex-wrap">
            <div class="d-flex rounded border m-2"
                 v-for="tag in userTag">
                <div class=" rounded-start bg-info p-2">
                    <div class="me-2">{{ tag.tagName }}</div>
                </div>
                <div class="p-2">{{ dayjs(tag.createdDateTime).format('YYYY/MM/DD') }}</div>
            </div>
        </div>
        <EditTagDialog v-if="editTagDialogVisible"
                       :visible="editTagDialogVisible"
                       @close="closeEditTagDialog" />
        <AddTagDialog v-if="addTagDialogVisible"
                      :visible="addTagDialogVisible"
                      :selectedUserTag="userTag"
                      @close="closeAddTagDialog" />
    </div>

</template>