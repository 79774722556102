<script setup>
import { ref, computed, watch } from 'vue';
import { useUtils } from '@/utils/index';
import { ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const utils = useUtils();
const emits = defineEmits(['save'])
const formData = defineModel(
    {
        default: {
            title: '',
            content: '',
            fileList: [],
            editMode: false
        }
    })
const tempData = ref({
    title: '',
    content: '',
    fileList: [],
});

const filesLength = computed(() => tempData.value.fileList.length);
const filesLimit = 5
const textLimit = 300
const formatLinks = computed(() => {
    const urlRegex = /(https?:\/\/[^\s<]+)/gi;
    // 替換 URL 為 <a> 連結，但保留編輯內容

    return formData.value.content
        .replaceAll('\n', '<br>')
        .replaceAll(' ', '&nbsp;')
        .replace(urlRegex, `<a href="$1" target="_blank" contenteditable="false">$1</a>`)
        .replaceAll(/(.+?)(<br>|$)/g, '<p class="mb-2">$1</p>');


})
const handleNotePreview = (file) => {
    if (file.response == undefined) {
        window.open(file.url);
    } else {
        window.open(file.response.url);
    }
}
const deletefile = (file) => {
    const vm = utils;

    vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/files/file/${file.name}`)
        .then((res) => {
            if (res.data) {
                ElMessage.success(t("LineBotAutoReply.Messagebox.UploadSuccess"));
            }
            else {
                ElMessage.success(t("LineBotAutoReply.Messagebox.UploadFailed"));
            }
        })
        .catch((err) => {
            console.log(err);
            vm.notify("error", t("LineBotAutoReply.Messagebox.UploadFailed"));
        });
    tempData.value.fileList = tempData.value.fileList.filter((item) => item.uid !== file.uid);
}
const uploadFile = (param) => {
    const vm = utils;
    let formData = new FormData();
    formData.append("file", param.file);
    vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/files/file`, formData)
        .then((res) => {
            console.log(res.data);
            const fileName = res.data.split('/').pop();
            tempData.value.fileList.push({
                uid: param.file.uid,
                name: fileName,
                url: res.data
            });
            ElMessage.success(t("LineBotAutoReply.Messagebox.UploadSuccess"));
        })
        .catch((err) => {
            console.log(err);
            vm.notify("error", t("LineBotAutoReply.Messagebox.UploadFailed"));
        });
}
const handleSave = () => {
    tempData.value.editMode = false
    formData.value = { ...tempData.value }
    emits('save')

}

watch(formData, (value) => {
    if (value.editMode) {
        tempData.value = { ...formData.value }
    }
}, { deep: true })

</script>

<template>
    <div class="d-flex flex-column gap-2">
        <div class="d-flex flex-column gap-2">
            <div class="d-flex align-items-center ">
                <el-input v-if="formData.editMode"
                          class="w-100"
                          type="text"
                          :placeholder="t('LineBotAutoReply.Title')"
                          show-word-limit
                          maxlength="100"
                          :rows="2"
                          v-model="tempData.title" />
                <span v-else
                      class="border rounded-1 p-2 w-100 text-break">{{ t("LineBotAutoReply.Question") }}：{{
                        formData.title
                        || '' }}</span>
            </div>
            <el-input v-model="tempData.content"
                      v-if="formData.editMode"
                      type="textarea"
                      :placeholder="t('LineBotAutoReply.Content')"
                      show-word-limit
                      :maxlength="textLimit"
                      :rows="6" />
            <div class="d-flex  gap-1 border rounded-1 p-2 w-100 content-height"
                 v-else>
                <span>></span>
                <div v-html="formatLinks"></div>
            </div>

        </div>

        <el-upload v-if="formData.editMode"
                   class=" border rounded-3 p-3 mb-2"
                   action="https://jsonplaceholder.typicode.com/posts/"
                   :on-preview="handleNotePreview"
                   :on-remove="(file) => { deletefile(file); }"
                   :http-request="(file) => { uploadFile(file); }"
                   :limit="filesLimit"
                   :file-list="tempData.fileList"
                   accept=".jpg,.png"
                   list-type="picture-card">
            <template #trigger>

                <el-button size="small"
                           type="primary"
                           link><i class="fa-duotone fa-solid fa-arrow-up-from-bracket me-1"></i>
                    <span>{{ $t("Note.Image") }}</span></el-button>
            </template>
            <template #tip>
                <div class="d-flex justify-content-end gap-1">
                    <span class="upload-text">{{ filesLength }}</span>
                    <span class="upload-text">/</span>
                    <span class="upload-text">{{ filesLimit }}</span>
                </div>
            </template>
        </el-upload>
        <div class="border rounded-1 p-2 w-100"
             v-else-if="formData.fileList.length !== 0">
            <div class="
             d-flex
             gap-2">
                <span>附件：</span>
                <img v-for="file in formData.fileList"
                     :key="file.uid"
                     :src="file.url"
                     class="img-thumbnail img-fluid resImage" />
            </div>

        </div>
        <div v-if="formData.editMode"
             class="d-flex align-items-end">
            <el-button class="ms-auto"
                       @click="handleSave"
                       type="info"
                       plain
                       size="small">{{ t("LineBotAutoReply.Save")
                }}</el-button>
        </div>
    </div>
</template>
<style scope>
.AddNoteTitleInput {
    border: none;
    padding: 0.5em 0;
    width: 100%;
}

.upload-text {
    font-size: 12px;
    color: #909399 !important;
}

.editable {
    width: 100%;
    min-height: 100px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 16px;
    outline: none;
}

.resImage {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.content-height {
    height: 150px;
}

.el-upload--picture-card {
    width: 60px;
    height: 20px;
    line-height: 80px;
}

.el-upload--picture-card i {
    font-size: unset;
}

.el-upload-list--picture-card .el-upload-list__item {
    width: 80px !important;
    height: 80px !important;
}
</style>