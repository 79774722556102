<template>
	<div>
		<el-button type="danger" @click="cancel">{{ $t("Home.Cancel") }}</el-button>
		<el-button type="primary" @click="confirm">{{
			$t("Home.Confirm")
		}}</el-button>
	</div>
</template>

<script>
export default {
	name: "EditButtonGroup",
	methods: {
		cancel() {
			this.$emit("cancel", "ruleForm");
		},
		confirm() {
			this.$emit("confirm", "ruleForm");
		},
	},
	props: ["showButton"],
};
</script>
