<template>
	<UserActiveComponent />
	<el-divider class="tagDivide" />
	<el-input
		class="mb-2"
		v-model="filterText"
		placeholder="輸入關鍵字"
	></el-input>

	<div class="recordView mb-3">
		<ul
			class="list-group list-group-flush recordView"
			ref="recordList"
			@scroll="handleScroll"
		>
			<li
				v-for="i in filteredList"
				:key="i"
				class="list-group-item disabled"
				aria-disabled="true"
			>
				<!-- <div class="mb-2">No: {{ i.id }}</div> -->
				<div class="mb-2 text-secondary">
					<span class="op-7">Date:</span>
					<span class="op-7">{{ i.messageTime }} </span>
				</div>
				<div class="mb-2 op-7">Question:</div>
				<div class="mb-2 text-dark lh-13" v-html="i.requestMessage"></div>
				<div class="mb-2 op-7">Response:</div>
				<div class="mb-2 text-dark lh-13" v-html="i.replyMessage"></div>
			</li>
		</ul>
		<!-- 當沒有符合條件的資料時顯示 -->
		<div v-if="filteredList.length === 0" class="text-center text-muted">
			查無相關資料
		</div>
	</div>
</template>

<script>
import UserActiveComponent from "../components/ClientCenter/UserActiveComponent.vue";
import { useClientCenterStore } from "@/store/clientCenterStore";
import { storeToRefs } from "pinia";

export default {
	name: "RecordView",
	components: {
		UserActiveComponent,
	},
	data() {
		return {
			responseList: [],
			filterText: "",
			targetDataIndex: 0,
			queryCount: 100,
		};
	},
	setup() {
		const clientCenterStore = useClientCenterStore();
		const { selectedUser } = storeToRefs(clientCenterStore); // 確保 selectedUser 是響應式的

		return {
			selectedUser,
			clientCenterStore,
		};
	},
	computed: {
		filteredList() {
			if (!this.filterText) return this.responseList;
			const keyword = this.filterText.toLowerCase();
			return this.responseList
				.filter((item) => {
					return (
						String(item.id).includes(keyword) ||
						item.messageTime.toLowerCase().includes(keyword) ||
						item.requestMessage.toLowerCase().includes(keyword) ||
						item.replyMessage.toLowerCase().includes(keyword)
					);
				})
				.map((item) => {
					const highlight = (text) => {
						if (!text) return text;
						const regex = new RegExp(`(${keyword})`, "gi");
						return text.replace(regex, `<span class="highlight">$1</span>`);
					};
					return {
						...item,
						messageTime: highlight(item.messageTime),
						requestMessage: highlight(item.requestMessage),
						replyMessage: highlight(item.replyMessage),
					};
				});
		},
	},
	watch: {
		selectedUser: {
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					//換人之後  targetDataIndex 刷成0
					this.targetDataIndex = 0;
					this.getUserHistory();
				}
			},
			immediate: true, // 進入頁面時立即執行
		},
	},
	methods: {
		async getUserHistory() {
			let vm = this;

			vm.fetchAPI(
				"GET",
				`${
					process.env.VUE_APP_URL_API
				}/LineBot/LineBotMessageRecordByuserIdAndCamperId?userId=${
					this.selectedUser.id
				}&camperId=${vm.currentUser().id}&queryCount=${
					vm.queryCount
				}&targetDataIndex=${vm.targetDataIndex}`
			).then((res) => {
				vm.responseList.push(...res.data);
				vm.targetDataIndex += vm.queryCount;
			});
		},
		handleScroll() {
			let element = this.$refs.recordList;
			if (
				element.scrollTop + element.clientHeight >=
				element.scrollHeight - 5
			) {
				this.getUserHistory(); // 滾動到底部時載入更多
			}
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs.recordList.addEventListener("scroll", this.handleScroll);
		});
	},
};
</script>
<style>
/* .highlight {
	background-color: yellow !important;
	color: black;
	font-weight: bold;
	padding: 2px;
	border-radius: 3px;
} */
</style>
<style lang="scss" scoped>
.recordView {
}
.op-7 {
	opacity: 0.7;
}
.lh-13 {
	line-height: 1.3;
}
.recordView {
	height: 100%; // 設定固定高度，根據需求調整
	overflow-y: auto; // 讓內容可滾動
}

::v-deep(.highlight) {
	background-color: yellow;
	color: black;
	font-weight: bold;
}
</style>
