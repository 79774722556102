<script setup>
import { useClientCenterStore } from '@/store/clientCenterStore'
import { useUtils } from '@/utils/index'
import { ElMessage } from 'element-plus'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const clientCenterStore = useClientCenterStore()
const utils = useUtils()
const { selectedUser, tags } = storeToRefs(clientCenterStore)
const { t } = useI18n()
const props = defineProps({
    visible: Boolean,
    selectedUserTag: Array,
})

const tagForm = ref({
    name: '',
    selected: props.selectedUserTag.map(tag => tag.tagId),
})

const addUserTag = computed(() => {
    return tagForm.value.selected.filter(tag => !props.selectedUserTag.map(tag => tag.tagId).includes(tag))
})
const removeUserTag = computed(() => {
    return props.selectedUserTag.map(tag => tag.tagId).filter(tag => !tagForm.value.selected.includes(tag))
})
const addTag = async (name) => {
    if (name) {
        await utils.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Enterprise/IndividualUserTag`, {
            name: name,
        }).then(() => {
            tagForm.value.name = ''
            clientCenterStore.getUserTagList()
            ElMessage.success(t('QACenter.TagSet.Dialog.TagUpdateCompleted'))
        }).catch((e) => {
            if (e.response.data.Status === 1007) {
                ElMessage.error(t('QACenter.TagSet.Dialog.TagAddFailed'))

            }
        })

    }
}
const delMemberUserTag = async () => {
    for (const tag of removeUserTag.value) {
        await utils.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/Enterprise/IndividualMemberUserTag/${selectedUser.value.id}/${tag}`)
    }
    selectedUser.value.tags = tagForm.value.selected || []
}
const setMemberUserTag = async () => {
    await utils.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Enterprise/IndividualMemberUserTag`, addUserTag.value.map(tag => {
        return {
            userId: selectedUser.value.id,
            tagId: tag,
        }
    }))
    selectedUser.value.tags = tagForm.value.selected || []
}
const handleSave = async () => {
    if (addUserTag.value.length > 0) {
        await setMemberUserTag()
    }
    if (removeUserTag.value.length > 0) {
        await delMemberUserTag()
    }
    ElMessage.success(t('QACenter.TagSet.Dialog.TagUpdateCompleted'))
    emits('close')
}
const emits = defineEmits(['close'])
</script>
<template>
    <el-dialog v-model="props.visible"
               :title="$t('QACenter.TagSet.Add')"
               width="80%"
               @close="emits('close')">
        <el-form :model="tagForm">
            <el-form-item :label="$t('QACenter.TagSet.Name')">
                <el-input v-model="tagForm.name"
                          autocomplete="off"
                          :placeholder="$t('QACenter.TagSet.InputTag')" />
                <div class="ms-3">
                    <el-button type="primary"
                               @click="addTag(tagForm.name)">{{ $t('QACenter.TagSet.Add') }}</el-button>
                </div>
            </el-form-item>
            <el-divider />
            <el-form-item :label="$t('QACenter.TagSet.Label')">
                <el-checkbox-group style="text-align: start"
                                   v-model="tagForm.selected"
                                   @change="selectTag">
                    <el-checkbox v-for="item in tags"
                                 :key="item.id"
                                 :label="item.id">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
                <div class="ms-3">
                    <el-button type="primary"
                               @click="handleSave">{{ $t('QACenter.TagSet.Save') }}</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>