<template>
	<el-form
		@submit.prevent
		:model="ruleForm"
		:rules="rules"
		ref="ruleForm"
		:label-position="labelPosition"
	>
		<div class="password">
			<div class="password_title">
				<h3>{{ $t("Password.Password") }}</h3>
				<div>
					<button
						class="edit_button"
						@click="editPassword"
						v-if="!isPasswordClicked"
						data-bs-toggle="collapse"
						data-bs-target="#passwordEdit"
					>
						<span>{{ $t("Password.EditPassword") }}</span>
					</button>
					<div v-else>
						<button
							class="edit_button"
							data-bs-toggle="collapse"
							data-bs-target="#passwordEdit"
							@click="cancelForm('ruleForm')"
						>
							<span>{{ $t("Password.Cancel") }}</span>
						</button>
						<button
							class="edit_button confirmBtn"
							@click="submitForm('ruleForm')"
						>
							<span>{{ $t("Password.Confirm") }}</span>
						</button>
					</div>
				</div>
			</div>
			<div class="collapse mt-3" id="passwordEdit">
				<el-form-item :label="$t('Password.OldPassword')" prop="oldPassword">
					<el-input
						ref="autoFocus"
						type="password"
						v-model="ruleForm.oldPassword"
						autocomplete="off"
						:placeholder="$t('Password.InputOldPassword')"
						show-password
					></el-input>
				</el-form-item>
				<el-form-item
					:label="$t('Password.InputNewPassword')"
					prop="newPassword"
				>
					<el-input
						type="password"
						v-model="ruleForm.newPassword"
						autocomplete="off"
						:placeholder="$t('Password.InputNewPassword')"
						show-password
					></el-input>
				</el-form-item>
				<el-form-item
					:label="$t('Password.InputNewPasswordAgain')"
					prop="passwordConfirm"
				>
					<el-input
						type="password"
						v-model="ruleForm.passwordConfirm"
						@keydown.enter.prevent="submitForm('ruleForm')"
						autocomplete="off"
						:placeholder="$t('Password.InputNewPasswordAgain')"
						show-password
					></el-input>
				</el-form-item>
			</div>
		</div>
	</el-form>
</template>

<script>
export default {
	name: "Password",
	data() {
		var validateOldPassword = (rule, value, callback) => {
			if (value === "") {
				callback(new Error(this.$t("Password.Script.OldPasswordCannotEmpty")));
			} else {
				callback();
			}
		};
		var validateNewPassword = (rule, value, callback) => {
			if (value === "") {
				callback(new Error(this.$t("Password.Script.NewPasswordCannotEmpty")));
			} else if (this.ruleForm.oldPassword === this.ruleForm.newPassword) {
				callback(new Error(this.$t("Password.Script.NewPasswordCannotBeSame")));
			} else {
				if (this.ruleForm.passwordConfirm !== "") {
					this.$refs.ruleForm.validateField("passwordConfirm");
				}
				callback();
			}
		};
		var validatePasswordConfirm = (rule, value, callback) => {
			if (value === "") {
				callback(
					new Error(this.$t("Password.Script.ConfirmPasswordCannotEmpty"))
				);
			} else if (value !== this.ruleForm.newPassword) {
				callback(new Error(this.$t("Password.Script.NewPasswordMismatch")));
			} else {
				callback();
			}
		};
		return {
			isPasswordClicked: false,
			labelPosition: "top",
			ruleForm: {
				oldPassword: "",
				newPassword: "",
				passwordConfirm: "",
			},
			rules: {
				oldPassword: [{ validator: validateOldPassword, trigger: "change" }],
				newPassword: [
					{ validator: validateNewPassword, trigger: "change" },
					{
						pattern: /^(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%]).{8,}$/,
						message: this.$t("Password.Script.PasswordConditions"),
						trigger: "change",
					},
				],
				passwordConfirm: [
					{ validator: validatePasswordConfirm, trigger: "change" },
				],
			},
		};
	},
	methods: {
		editPassword() {
			this.isPasswordClicked = true;
			this.scroll();
			this.$refs.autoFocus.focus();
		},
		scroll() {
			setTimeout(() => {
				window.scrollTo(0, document.body.scrollHeight);
			}, 150);
		},
		cancelForm(formName) {
			this.isPasswordClicked = false;
			this.$refs[formName].resetFields();
		},
		submitForm(formName) {
			const vm = this;
			vm.$refs[formName].validate((valid) => {
				if (valid) {
					let data = {
						Password: vm.ruleForm.oldPassword,
						NewPassword: vm.ruleForm.newPassword,
					};
					vm.fetchAPI(
						"put",
						`${process.env.VUE_APP_URL_API}/Account/passwordEdit`,
						data
					)
						.then(() => {
							this.notify(
								"success",
								this.$t("Password.Script.PasswordUpdateSuccess")
							);
						})
						.catch((err) => {
							console.log(err);
							this.notify(
								"error",
								`${this.$t("Password.Script.PasswordUpdateFail")}, ${
									err.response.data.Msg
								}!`
							);
						})
						.finally(() => {
							vm.cancelForm("ruleForm");
							document.getElementById("passwordEdit").classList.remove("show");
						});
				} else {
					vm.notify("warning", this.$t("Password.Script.CheckInputData"));
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.password {
	h3 {
		font-size: 1.1rem;
		font-weight: $main_fontWeight;
		padding-bottom: 0.5rem;
	}
	.edit_button {
		background-color: $main-color;
		border-radius: 0.5rem;
		padding: 0.3rem;
		border: none;
		margin-right: 0.2rem;
		transition: $main_transition;
		span {
			color: #fff;
			font-size: 1rem;
			transition: $main_transition;
			font-weight: $main_fontWeight;
		}
		&:hover {
			background-color: $main_color_hover;
		}
	}
	margin-top: 1.5rem;
	padding-top: 0.5rem;
	padding-bottom: 2rem;
	border-top: 3px solid $main_color;
	.password_title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		h3 {
			padding: 0;
		}
	}
	.collapse {
		& > div {
			display: flex;
			flex-direction: column;
		}
		span {
			font-size: 0.9rem;
		}
	}
	:deep #passwordEdit .el-form-item {
		border: none !important;
	}
}
</style>
