<script setup>
import { onMounted, ref, watch } from 'vue'
import { useUtils } from '@/utils/index'
import AddDialog from './components/AddDialog.vue'
import { ElMessageBox } from 'element-plus'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const utils = useUtils()
const active = ref(false)
const formData = ref([])
const limit = 5
const add = () => {
    formData.value.push({
        detail: {
            title: '',
            content: '',
            fileList: [],
            editMode: true
        }
    })
}
const getFastQA = async () => {
    await utils.fetchAPI("get", `${process.env.VUE_APP_URL_API}/linebot/qa`).then((res) => {
        const data = res.data
        active.value = data.item1
        formData.value = data.item2.map((item) => {
            if (item.answer.includes('[*')) {
                const fileUrls = item.answer.match(/\[\*(.*?)\*\]/g)
                const fileList = fileUrls.map((fileUrl) => {

                    return {
                        name: fileUrl.replace(/(\[\*|\*\])/g, '').split('/').pop(),
                        url: fileUrl.replace(/(\[\*|\*\])/g, '')
                    }
                })
                return {
                    detail: {
                        title: item.question,
                        content: item.answer.replace(/\[\*(.*?)\*\]/g, ''),
                        fileList: fileList,
                        editMode: false
                    }
                }
            }
            return {
                detail: {
                    title: item.question,
                    content: item.answer,
                    fileList: [],
                    editMode: false
                },
                isEnable: item.isEnable
            }
        })
    })

}
const saveFastQA = async () => {

    const data = formData.value.map((item, index) => {
        let fileUrl = ''
        if (item.detail.fileList.length > 0) {
            fileUrl = `[*${item.detail.fileList.map((file) => file.url).join(',')}*]`
        }
        return {
            question: item.detail.title,
            answer: item.detail.content + fileUrl,
            seq: `${index + 1}`,
        }
    })
    await utils.fetchAPI("post", `${process.env.VUE_APP_URL_API}/linebot/qa`, { IsEnable: active.value, QAInfos: data }).then((res) => {
        if (res.data) {
            utils.showSuccessMessage(t("LineBotAutoReply.Messagebox.SaveSuccess"));
        }
    })
}
const handelRemove = async (index) => {
    ElMessageBox.confirm(t("LineBotAutoReply.Messagebox.ConfirmMsg"), t("LineBotAutoReply.Messagebox.Alert"), {
        confirmButtonText: t("LineBotAutoReply.Messagebox.Confirm"),
        cancelButtonText: t("LineBotAutoReply.Messagebox.Cancel"),
        type: 'warning'
    }).then(async () => {
        formData.value.splice(index, 1)
        await saveFastQA()
    }).catch(() => {
    })

}

onMounted(async () => {
    await getFastQA()
})
</script>
<template>
    <div class="d-flex flex-column gap-2">

        <div class="d-flex flex-column gap-2 align-items-center">
            <div class="w-50">
                <el-switch style="--el-switch-on-color: #13ce66;
                                --el-switch-off-color: #ff4949;"
                           v-model="active"
                           @change="saveFastQA"
                           size="large"
                           :active-text="t('LineBotAutoReply.Switch.Active')"
                           :inactive-text="t('LineBotAutoReply.Switch.UnActive')" />
            </div>
            <div class="border p-3 w-50"
                 :class="data.detail.editMode ? 'border-success' : ''"
                 v-for="(data, index) in formData"
                 :key="index">
                <div class="d-flex justify-content-between align-items-center mb-2">
                    <div>
                        <span>{{ index + 1 }}</span>
                        <span>/</span>
                        <span>5</span>
                    </div>
                    <div v-if="data.detail.editMode">{{ t("LineBotAutoReply.Editing") }}...</div>
                    <div v-else>
                        <el-button type="text"
                                   @click="data.detail.editMode = true">{{ t("LineBotAutoReply.Edit") }}</el-button>
                        <el-button type="text"
                                   @click="handelRemove(index)">{{ t("LineBotAutoReply.Delete") }}</el-button>
                    </div>
                </div>
                <AddDialog v-model="data.detail"
                           @save="saveFastQA(261)"></AddDialog>
            </div>

            <el-button v-if="formData.length < limit"
                       type="primary"
                       @click="add">{{ t("LineBotAutoReply.Add") }}</el-button>
        </div>

    </div>
</template>
<style scoped>
:deep .el-switch__label.is-active {
    color: #000000;
}

:deep .el-switch__label {
    color: #b9b9b9;
}
</style>