<template>
	<el-tabs
		class="sticky-tabs"
		type="card"
		v-model="activeName"
		:class="{ tabMargin: tabMargin }"
		@tab-click="handleTabClick"
	>
		<el-tab-pane name="note">
			<template v-slot:label>{{ $t("Home.EditInfo") }}</template>
			<Note ref="childNote" />
		</el-tab-pane>
		<el-tab-pane
			name="note1"
			v-if="currentUser().isKeyLogin || currentUser().id == 206"
			:label="$t('Home.Editprompt')"
		>
			<div class="d-flex">
				<div class="p-2 mx-auto" style="max-width: 500px">
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom">{{ $t("Prompt.Title") }}</div>
						<el-input
							:placeholder="$t('Prompt.InputTitle')"
							v-model="promptData.user_name"
						/>
					</el-card>

					<el-card class="box-card" shadow="hover">
						<div class="labelcustom">{{ $t("Prompt.BasicPromptWords") }}</div>
						<el-input
							type="textarea"
							:autosize="{ minRows: 4 }"
							:placeholder="$t('Prompt.InputBasicPromptWords')"
							v-model="promptData.promptDesc2"
						>
						</el-input>
					</el-card>
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom">
							{{ $t("Prompt.ClassificationPromptWords") }}
						</div>
						<div class="mt-2 text-start">
							<el-switch
								style="display: block"
								v-model="promptData.isEnbaled"
								active-color="#13ce66"
								inactive-color="#ff4949"
								:active-text="$t('Prompt.Open')"
								:inactive-text="$t('Prompt.Close')"
							>
							</el-switch>
						</div>
						<el-input
							class=""
							type="textarea"
							:autosize="{ minRows: 4 }"
							:placeholder="$t('Prompt.InputClassificationPromptWords')"
							v-model="promptData.promptDesc3"
						>
						</el-input>
					</el-card>

					<!-- task1 -->
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom d-flex align-items-center">
							<div>{{ $t("Prompt.TaskName_TaskDesc", { num: 1 }) }}</div>
							<el-checkbox
								class="ms-auto"
								v-model="checkedTasks[0]"
								@change="updateTask(0)"
								:label="$t('Prompt.GetCategoryNameOnly')"
							/>
						</div>
						<el-input
							:placeholder="$t('Prompt.InputTaskName')"
							v-model="promptData.task1.desc"
							class="mb-2"
						>
						</el-input>
						<el-input
							type="textarea"
							:placeholder="$t('Prompt.InputTaskDesc')"
							v-model="promptData.task1.tipWord"
							:autosize="{ minRows: 4 }"
						>
						</el-input>
					</el-card>

					<!-- task2 -->
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom d-flex align-items-center">
							<div>{{ $t("Prompt.TaskName_TaskDesc", { num: 2 }) }}</div>
							<el-checkbox
								class="ms-auto"
								v-model="checkedTasks[1]"
								@change="updateTask(1)"
								:label="$t('Prompt.GetCategoryNameOnly')"
							/>
						</div>

						<el-input
							:placeholder="$t('Prompt.InputTaskName')"
							v-model="promptData.task2.desc"
							class="mb-2"
						>
						</el-input>
						<el-input
							type="textarea"
							:placeholder="$t('Prompt.InputTaskDesc')"
							v-model="promptData.task2.tipWord"
							:autosize="{ minRows: 4 }"
						>
						</el-input>
					</el-card>

					<!-- task3 -->
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom d-flex align-items-center">
							<div>{{ $t("Prompt.TaskName_TaskDesc", { num: 3 }) }}</div>
							<el-checkbox
								class="ms-auto"
								v-model="checkedTasks[2]"
								@change="updateTask(2)"
								:label="$t('Prompt.GetCategoryNameOnly')"
							/>
						</div>

						<el-input
							:placeholder="$t('Prompt.InputTaskName')"
							v-model="promptData.task3.desc"
							class="mb-2"
						>
						</el-input>
						<el-input
							type="textarea"
							:placeholder="$t('Prompt.InputTaskDesc')"
							v-model="promptData.task3.tipWord"
							:autosize="{ minRows: 4 }"
						>
						</el-input>
					</el-card>

					<!-- task4 -->
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom d-flex align-items-center">
							<div>{{ $t("Prompt.TaskName_TaskDesc", { num: 4 }) }}</div>
							<el-checkbox
								class="ms-auto"
								v-model="checkedTasks[3]"
								@change="updateTask(3)"
								:label="$t('Prompt.GetCategoryNameOnly')"
							/>
						</div>

						<el-input
							:placeholder="$t('Prompt.InputTaskName')"
							v-model="promptData.task4.desc"
							class="mb-2"
						>
						</el-input>
						<el-input
							type="textarea"
							:placeholder="$t('Prompt.InputTaskDesc')"
							v-model="promptData.task4.tipWord"
							:autosize="{ minRows: 4 }"
						>
						</el-input>
					</el-card>

					<!-- task5 -->
					<el-card class="box-card" shadow="hover">
						<div class="labelcustom d-flex align-items-center">
							<div>{{ $t("Prompt.TaskName_TaskDesc", { num: 5 }) }}</div>
							<el-checkbox
								class="ms-auto"
								v-model="checkedTasks[4]"
								@change="updateTask(4)"
								:label="$t('Prompt.GetCategoryNameOnly')"
							/>
						</div>

						<el-input
							:placeholder="$t('Prompt.InputTaskName')"
							v-model="promptData.task5.desc"
							class="mb-2"
						>
						</el-input>
						<el-input
							type="textarea"
							:placeholder="$t('Prompt.InputTaskDesc')"
							v-model="promptData.task5.tipWord"
							:autosize="{ minRows: 4 }"
						>
						</el-input>
					</el-card>

					<div class="text-end">
						<el-button type="primary" plain round @click="getData()">{{
							$t("Prompt.Cancel_Revert")
						}}</el-button>
						<el-button type="success" plain round @click="saveData()">{{
							$t("Prompt.Save")
						}}</el-button>
					</div>
				</div>
			</div>
		</el-tab-pane>
		<el-tab-pane name="note2">
			<template v-slot:label>{{ $t("Home.ResponseLineBot") }}</template>
			<ResponseLineBot ref="childResponseLineBot" />
		</el-tab-pane>
		<el-tab-pane name="basicSetting" :label="$t('Home.LineBotGPTSetting')">
			<div class="basicSetting">
				<el-tabs
					:tab-position="$store.getters.mobileSize ? 'top' : 'left'"
					class="mt-3"
					stretch="true"
				>
					<el-tab-pane
						v-if="currentUser().isKeyLogin"
						:label="$t('Home.LineBotInfo')"
					>
						<div class="content">
							<h5 class="dangerText">
								{{ $t("Home.InputLineBotTip") }}
							</h5>
							<el-form
								@submit.prevent
								:model="channelSettings"
								ref="channelSettings"
								label-position="top"
							>
								<div class="channelSecret">
									<el-form-item prop="channelSecret" label="● Channel secret">
										<el-input
											@click="changeToEdit(1, 'channel')"
											ref="autoFocus1_channel"
											v-model="channelSettings.secret"
											@keydown.enter.prevent="
												channelEditCompleted(1, 'channel')
											"
											placeholder="Input Channel secret"
											:readonly="!isChannelSettingsClickedArray[1]"
											:class="{ disActive: !isChannelSettingsClickedArray[1] }"
										/>
										<div class="edit_group">
											<EditInputButton
												@click="changeToEdit(1, 'channel')"
												v-if="!isChannelSettingsClickedArray[1]"
											/>
											<EditButtonGroup
												class="editButtonGroup"
												v-else
												@cancel="changeToOrigin(1, 'channel')"
												@confirm="channelEditCompleted(1, 'channel')"
											/>
										</div>
									</el-form-item>
								</div>
								<div class="channelAccessToken">
									<el-form-item
										prop="channelAccessToken"
										label="● Channel access token"
									>
										<el-input
											@click="changeToEdit(2, 'channel')"
											ref="autoFocus2_channel"
											v-model="channelSettings.accessToken"
											@keydown.enter.prevent="
												channelEditCompleted(2, 'channel')
											"
											placeholder="Input Channel access token"
											:readonly="!isChannelSettingsClickedArray[2]"
											:class="{ disActive: !isChannelSettingsClickedArray[2] }"
										/>
										<div class="edit_group">
											<EditInputButton
												@click="changeToEdit(2, 'channel')"
												v-if="!isChannelSettingsClickedArray[2]"
											/>
											<EditButtonGroup
												class="editButtonGroup"
												v-else
												@cancel="changeToOrigin(2, 'channel')"
												@confirm="channelEditCompleted(2, 'channel')"
											/>
										</div>
									</el-form-item>
								</div>
							</el-form>
						</div>
					</el-tab-pane>
					<el-tab-pane :label="$t('Home.GPTAuto-ReplySettings')">
						<div class="content">
							<el-switch
								style="
									--el-switch-on-color: #13ce66;
									--el-switch-off-color: #ff4949;
								"
								v-model="isAllWebhookActive"
								size="large"
								:active-text="$t('Home.EnableFeature')"
								:inactive-text="$t('Home.DisableFeature')"
								@change="updateAllBasicSetting()"
							/>
							<ul class="list-group list-group-flush userList mt-3">
								<li
									v-for="i in userSettings"
									:key="i"
									class="list-group-item p-2 flex-nowrap"
								>
									<div
										class="d-flex align-items-center justify-content-between"
									>
										<div class="d-flex align-items-center">
											<el-avatar
												size="medium"
												:src="
													i.memberAvatar == ''
														? 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
														: i.memberAvatar
												"
											></el-avatar>
											<div class="chatInfo ms-2">
												<div class="userName" style="width: 11rem">
													{{ i.memberName == "" ? "None" : i.memberName }}
												</div>
											</div>
										</div>
										<el-switch
											:disabled="!isAllWebhookActive"
											class="flex-nowrap"
											v-model="i.isWebhookActive"
											size="small"
											:active-text="$t('Home.EnableFeature')"
											:inactive-text="$t('Home.DisableFeature')"
											@change="updateBasicSetting(i)"
										/>
									</div>
								</li>
							</ul>
						</div>
					</el-tab-pane>
					<el-tab-pane :label="$t('Home.GPTResponseTextSettings')">
						<div class="content">
							<el-form
								@submit.prevent
								:model="keywordSettings"
								ref="keywordSettings"
								label-position="top"
							>
								<div class="dataNotFound">
									<el-form-item
										prop="dataNotFound"
										:label="$t('Home.DataNotFound')"
									>
										<el-input
											@click="changeToEdit(1, 'keyword')"
											ref="autoFocus1_keyword"
											v-model="keywordSettings.dataNotFound"
											@keydown.enter.prevent="
												keywordEditCompleted(1, 'keyword')
											"
											:placeholder="$t('Home.NotFoundDataMessage')"
											:readonly="!isKeywordSettingsClickedArray[1]"
											:class="{ disActive: !isKeywordSettingsClickedArray[1] }"
										/>
										<div class="edit_group">
											<EditInputButton
												@click="changeToEdit(1, 'keyword')"
												v-if="!isKeywordSettingsClickedArray[1]"
											/>
											<EditButtonGroup
												class="editButtonGroup"
												v-else
												@cancel="changeToOrigin(1, 'keyword')"
												@confirm="keywordEditCompleted(1, 'keyword')"
											/>
										</div>
									</el-form-item>
								</div>
								<div class="searchNotFound">
									<el-form-item
										prop="searchNotFound"
										:label="$t('Home.ResultNotFound')"
									>
										<el-input
											@click="changeToEdit(2, 'keyword')"
											ref="autoFocus2_keyword"
											v-model="keywordSettings.searchNotFound"
											@keydown.enter.prevent="
												keywordEditCompleted(2, 'keyword')
											"
											:placeholder="$t('Home.NotFoundResultMessage')"
											:readonly="!isKeywordSettingsClickedArray[2]"
											:class="{ disActive: !isKeywordSettingsClickedArray[2] }"
										/>
										<div class="edit_group">
											<EditInputButton
												@click="changeToEdit(2, 'keyword')"
												v-if="!isKeywordSettingsClickedArray[2]"
											/>
											<EditButtonGroup
												class="editButtonGroup"
												v-else
												@cancel="changeToOrigin(2, 'keyword')"
												@confirm="keywordEditCompleted(2, 'keyword')"
											/>
										</div>
									</el-form-item>
								</div>
								<div class="helpfulResponse">
									<el-form-item
										prop="helpfulResponse"
										:label="$t('Home.Helpful')"
									>
										<el-input
											@click="changeToEdit(3, 'keyword')"
											ref="autoFocus3_keyword"
											v-model="keywordSettings.helpfulResponse"
											@keydown.enter.prevent="
												keywordEditCompleted(3, 'keyword')
											"
											:placeholder="$t('Home.HelpfulMessage')"
											:readonly="!isKeywordSettingsClickedArray[3]"
											:class="{ disActive: !isKeywordSettingsClickedArray[3] }"
										/>
										<div class="edit_group">
											<EditInputButton
												@click="changeToEdit(3, 'keyword')"
												v-if="!isKeywordSettingsClickedArray[3]"
											/>
											<EditButtonGroup
												class="editButtonGroup"
												v-else
												@cancel="changeToOrigin(3, 'keyword')"
												@confirm="keywordEditCompleted(3, 'keyword')"
											/>
										</div>
									</el-form-item>
								</div>
								<div class="notHelpfulResponse">
									<el-form-item
										prop="notHelpfulResponse"
										:label="$t('Home.NotHelpful')"
									>
										<el-input
											@click="changeToEdit(4, 'keyword')"
											ref="autoFocus4_keyword"
											v-model="keywordSettings.notHelpfulResponse"
											@keydown.enter.prevent="
												keywordEditCompleted(4, 'keyword')
											"
											:placeholder="$t('Home.NotHelpfulMessage')"
											:readonly="!isKeywordSettingsClickedArray[4]"
											:class="{ disActive: !isKeywordSettingsClickedArray[4] }"
										/>
										<div class="edit_group">
											<EditInputButton
												@click="changeToEdit(4, 'keyword')"
												v-if="!isKeywordSettingsClickedArray[4]"
											/>
											<EditButtonGroup
												class="editButtonGroup"
												v-else
												@cancel="changeToOrigin(4, 'keyword')"
												@confirm="keywordEditCompleted(4, 'keyword')"
											/>
										</div>
									</el-form-item>
								</div>
								<div class="informResponse">
									<el-form-item
										prop="informResponse"
										:label="$t('Home.NotificationReceived')"
									>
										<el-input
											@click="changeToEdit(5, 'keyword')"
											ref="autoFocus5_keyword"
											v-model="keywordSettings.informResponse"
											@keydown.enter.prevent="
												keywordEditCompleted(5, 'keyword')
											"
											:placeholder="$t('Home.NotificationReceivedMessage')"
											:readonly="!isKeywordSettingsClickedArray[5]"
											:class="{ disActive: !isKeywordSettingsClickedArray[5] }"
										/>
										<div class="edit_group">
											<EditInputButton
												@click="changeToEdit(5, 'keyword')"
												v-if="!isKeywordSettingsClickedArray[5]"
											/>
											<EditButtonGroup
												class="editButtonGroup"
												v-else
												@cancel="changeToOrigin(5, 'keyword')"
												@confirm="keywordEditCompleted(5, 'keyword')"
											/>
										</div>
									</el-form-item>
								</div>
								<div class="informWithoutTextResponse">
									<el-form-item
										prop="informWithoutTextResponse"
										:label="
											$t('Home.ReceivedNon-TextMessage&NotificationSettings')
										"
										class="custom"
									>
										<el-switch
											class="flex-nowrap ms-3"
											v-model="keywordSettings.isNeedInformWithoutText"
											:active-text="$t('Home.EnableFeature')"
											:inactive-text="$t('Home.DisableFeature')"
											@change="keywordEditCompleted(6, 'keyword')"
										/>
										<div
											class="w-100 d-flex align-items-center justify-content-between"
										>
											<el-input
												@click="changeToEdit(6, 'keyword')"
												ref="autoFocus6_keyword"
												v-model="keywordSettings.informWithoutTextResponse"
												@keydown.enter.prevent="
													keywordEditCompleted(6, 'keyword')
												"
												:placeholder="$t('Home.ReceivedNonMessage')"
												:readonly="!isKeywordSettingsClickedArray[6]"
												:class="{
													disActive: !isKeywordSettingsClickedArray[6],
												}"
											/>
											<div class="edit_group">
												<EditInputButton
													@click="changeToEdit(6, 'keyword')"
													v-if="!isKeywordSettingsClickedArray[6]"
												/>
												<EditButtonGroup
													class="editButtonGroup"
													v-else
													@cancel="changeToOrigin(6, 'keyword')"
													@confirm="keywordEditCompleted(6, 'keyword')"
												/>
											</div>
										</div>
									</el-form-item>
								</div>
							</el-form>
						</div>
					</el-tab-pane>
					<el-tab-pane :label="$t('Home.EmbedSettingLabel')">
						<div class="content">
							<el-form
								@submit.prevent
								:model="embedSettings"
								ref="embedSettings"
								label-position="top"
							>
								<div class="d-flex align-items-center mb-2">
									<h5 class="normalText">{{ $t("Home.EmbedSettingTitle") }}</h5>
									<el-tooltip
										class="box-item"
										effect="dark"
										:content="$t('Home.EmbedSettingTip')"
										:placement="
											$store.getters.mobileSize ? 'bottom-end' : 'right'
										"
									>
										<div class="ms-2" circle>
											<i class="fa-solid fa-circle-question"></i>
										</div>
									</el-tooltip>
								</div>
								<div class="d-flex">
									<el-input
										v-model="embedSettings.embedUrl"
										size="large"
										readonly
									/>
									<el-button
										class="ms-2"
										size="large"
										type="primary"
										@click="copyText()"
										plain
										>{{ $t("Home.EmbedSettingCopy") }}</el-button
									>
								</div>
								<el-divider />
								<el-switch
									class="flex-nowrap"
									v-model="embedSettings.isAllowAllOrigin"
									:active-text="$t('Home.EmbedSettingEnableAll')"
									:inactive-text="$t('Home.EmbedSettingDisableAll')"
									@change="embedAllowAllUrl()"
								/>
							</el-form>
						</div>
					</el-tab-pane>
					<el-tab-pane :label="$t('Home.LineBotAutoReply')">
						<div class="content">
							<LineAutoReply />
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-tab-pane>
		<el-tab-pane
			v-if="currentUser().isKeyLogin || currentUser().id == 206"
			name="ItemRental"
			:label="$t('Home.EquipmenRental')"
		>
			<div class="p-2 mx-auto" style="max-width: 1200px">
				<el-card class="box-card" shadow="hover" style="overflow-x: auto">
					<div class="labelcustom">裝備庫存設定</div>
					<div class="text-end mb-2">
						<el-button
							type="info"
							plain
							@click="openRentalSetting(null)"
							size="small"
							>新增資料</el-button
						>
					</div>

					<el-table :border="true" :data="rentalTableDataFiltered">
						<el-table-column sortable fixed prop="name" label="項目">
						</el-table-column>
						<el-table-column sortable prop="total" label="總數量">
						</el-table-column>
						<el-table-column sortable prop="stocklevel" label="庫存水位">
						</el-table-column>
						<!-- <el-table-column sortable prop="lentQuantity" label="租借數量">
						</el-table-column>
						<el-table-column sortable prop="remainingQuantity" label="剩餘數量">
						</el-table-column> -->

						<el-table-column label="操作">
							<template #header>
								<el-input
									v-model="searchrentalTable"
									size="small"
									placeholder="搜尋"
								/>
							</template>
							<template v-slot="scope">
								<el-button
									@click="openRentalSetting(scope.row)"
									type="text"
									size="small"
									>編輯</el-button
								>
								<el-button
									@click="deleteItemRental(scope.row)"
									type="text"
									size="small"
									>刪除</el-button
								>
							</template>
						</el-table-column>
					</el-table>

					<el-pagination
						size="small"
						layout="prev, pager, next"
						v-model="rentalTable.currentPage"
						:page-size="rentalTable.pageSize"
						:total="rentalTableData.length"
						@current-change="handlePageChange_rentalTable"
					/>
				</el-card>
				<el-card class="box-card" shadow="hover" style="overflow-x: auto">
					<div class="labelcustom">用戶租借資料</div>

					<el-select
						v-model="calendarSelectItem"
						@change="
							(value) => {
								this.currentEquipment = rentalItemOption.find(
									(item) => item.value === value
								);

								if (value == '') {
									isAllSelected = true;
								} else {
									isAllSelected = false;
								}
								getCampAllEquipment();
							}
						"
						placeholder="請選擇"
					>
						<el-option
							v-for="item in rentalItemOption"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
					<!-- :class="{ 'hide-calendar': isAllSelected }" -->
					<div>
						<el-calendar
							v-model="selectedDate"
							:default-value="defaultDate"
							@input="onDateChange"
						>
							<template #dateCell="{ data }" v-if="!isAllSelected">
								<div class="custom-date-cell" @click="onDateClick(data)">
									<div class="calendar-time" style="font-size: small">
										{{ data.day.split("-").slice(2).join("") }}
									</div>
									<div class="mt-2">
										<span
											class="remark-text calendar-time"
											style="color: #35ab55; font-weight: 600; font-size: small"
										>
											{{ generateRemainingMap(data.day) }}
										</span>
										<div>
											<el-button
												class="mt-1"
												size="small"
												@click="openRentaListSetting(null, data)"
												>+</el-button
											>
										</div>
									</div>
								</div>
							</template></el-calendar
						>
					</div>

					<div class="text-end my-2 d-flex">
						<el-input
							v-model="searchItem"
							size="small"
							placeholder="搜尋"
							style="max-width: 300px"
						/>
						<el-button
							class="ms-2"
							size="small"
							type="danger"
							v-if="showAllTableData == false && isAllSelected == false"
							@click="showAllTableData = true"
							>{{ selectedDate.toLocaleDateString() }}
							<i class="fal fa-times ms-2"></i>
						</el-button>
						<el-checkbox
							class="ms-auto"
							v-model="showEscheat"
							label="顯示已歸還"
							size="small"
						/>
						<el-button size="small" @click="setStatus(true)"
							>設定已歸還</el-button
						>
						<el-button size="small" @click="setStatus(false)"
							>設定未歸還</el-button
						>
					</div>

					<el-table
						:row-style="tableRowStyle"
						:border="true"
						:data="rentalListTableDataFiltered"
						resizable
						@selection-change="handleSelectionChange"
					>
						<el-table-column type="selection" width="55" />

						<el-table-column sortable fixed prop="renterName" label="姓名">
						</el-table-column>
						<el-table-column sortable prop="renterCampsite" label="營位">
						</el-table-column>
						<el-table-column sortable prop="startDate" label="租借日">
							<template v-slot="scope">
								{{ formatDate(scope.row.startDate) }}
							</template>
						</el-table-column>
						<el-table-column sortable prop="endDate" label="歸還日">
							<template v-slot="scope">
								{{ formatDate(scope.row.endDate) }}
							</template>
						</el-table-column>
						<el-table-column sortable prop="itemName" label="項目">
						</el-table-column>
						<el-table-column sortable prop="rentalCount" label="數量">
						</el-table-column>
						<el-table-column sortable prop="status" label="狀態">
						</el-table-column>
						<el-table-column prop="remark" label="備註"> </el-table-column>
						<el-table-column>
							<template v-slot="scope">
								<div class="d-flex">
									<el-button
										@click="openRentaListSetting(scope.row)"
										type="text"
										size="small"
										>編輯</el-button
									>
									<el-button
										@click="deleteRentalList(scope.row)"
										type="text"
										size="small"
										>刪除</el-button
									>
								</div>
							</template>
						</el-table-column>
					</el-table>

					<el-pagination
						size="small"
						layout="prev, pager, next"
						v-model="rentalTableList.currentPage"
						:page-size="rentalTableList.pageSize"
						:total="rentalListTableDataFiltered.length"
						@current-change="handlePageChange_rentalTableList"
					/>
				</el-card>
			</div>
		</el-tab-pane>
		<el-tab-pane name="memberCenter" :label="$t('Home.Profile')">
			<MemberCenter ref="memberCenterRef"></MemberCenter>
		</el-tab-pane>
		<el-tab-pane name="qaCenter" :label="$t('Home.QACenter')">
			<QACenter ref="qaCenterRef"></QACenter>
		</el-tab-pane>
	</el-tabs>

	<el-dialog title="借用人資訊" v-model="rentalListdialogVisible">
		<el-form-item>
			<template #label>
				<span class="custom-label"
					>借用人姓名<span class="text-danger">*</span></span
				>
			</template>
			<el-input v-model="rentalListdialogForm.renterName"></el-input>
		</el-form-item>
		<el-form-item>
			<template #label>
				<span class="custom-label"
					>借用人營位<span class="text-danger">*</span></span
				>
			</template>
			<el-input v-model="rentalListdialogForm.renterCampsite"></el-input>
		</el-form-item>
		<el-form-item>
			<template #label>
				<span class="custom-label"
					>租借日期<span class="text-danger">*</span></span
				>
			</template>
			<el-date-picker
				v-model="rentalListdialogForm.startDate"
				type="date"
				placeholder="選擇日期"
			>
			</el-date-picker>
		</el-form-item>
		<el-form-item>
			<template #label>
				<span class="custom-label"
					>預計歸還日期<span class="text-danger">*</span></span
				>
			</template>
			<el-date-picker
				v-model="rentalListdialogForm.endDate"
				type="date"
				placeholder="選擇日期"
			>
			</el-date-picker>
		</el-form-item>
		<template v-if="rentalListdialogForm.id === null">
			<template v-for="(i, index) in rentalListdialogForm.items" :key="i">
				<div class="d-flex">
					<el-form-item>
						<template #label>
							<span class="custom-label"
								>項目<span class="text-danger">*</span></span
							>
						</template>
						<el-select v-model="i.itemId" placeholder="請選擇">
							<el-option
								v-for="item in getFilteredOptions(index).filter(
									(x) => x.value != ''
								)"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-button
						v-if="index > 0"
						class="ms-1"
						size="small"
						type="danger"
						@click="
							() => {
								rentalListdialogForm.items.splice(index, 1);
							}
						"
						>X</el-button
					>
				</div>
				<el-form-item class="border-bottom pb-2 mb-2">
					<template #label>
						<span class="custom-label"
							>數量<span class="text-danger">*</span></span
						>
					</template>
					<el-input v-model="i.rentalCount" type="number" min="0"></el-input>
				</el-form-item>
			</template>
			<el-button class="mb-2" type="success" size="small" @click="addItem">
				新增項目
			</el-button>
		</template>
		<template v-else>
			<el-form-item label="項目">
				<template #label>
					<span class="custom-label"
						>項目<span class="text-danger">*</span></span
					>
				</template>
				<el-select v-model="rentalListdialogForm.itemId" placeholder="請選擇">
					<el-option
						v-for="item in rentalItemOption.filter((x) => x.value != '')"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="數量">
				<template #label>
					<span class="custom-label"
						>數量<span class="text-danger">*</span></span
					>
				</template>
				<el-input
					v-model="rentalListdialogForm.rentalCount"
					type="number"
					min="0"
				></el-input>
			</el-form-item>
		</template>

		<el-form-item label="歸還狀態">
			<el-select v-model="rentalListdialogForm.status" placeholder="請選擇">
				<el-option
					v-for="item in statusOptions"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				>
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="備註">
			<el-input v-model="rentalListdialogForm.remark"></el-input>
		</el-form-item>
		<el-button @click="rentalListdialogVisible = false">取消</el-button>
		<el-button type="primary" @click="saveItemRentalList()">確定</el-button>
	</el-dialog>

	<el-dialog title="裝備庫存資訊" v-model="rentaldialogVisible">
		<el-form-item label="項目">
			<el-input v-model="rentaldialogForm.name"></el-input>
		</el-form-item>
		<el-form-item label="總數量">
			<el-input v-model="rentaldialogForm.total" type="number"></el-input>
		</el-form-item>
		<el-form-item label="庫存水位">
			<el-input v-model="rentaldialogForm.stocklevel" type="number"></el-input>
		</el-form-item>
		<!-- <el-form-item label="租借數量">
			<el-input
				:disabled="true"
				v-model="rentaldialogForm.lentQuantity"
				type="number"
			></el-input>
		</el-form-item>
		<el-form-item label="剩餘數量">
			<el-input
				:disabled="true"
				v-model="rentaldialogForm.remainingQuantity"
				type="number"
			></el-input>
		</el-form-item> -->

		<el-button @click="rentaldialogVisible = false">取消</el-button>
		<el-button type="primary" @click="saveItemRental()">確定</el-button>
	</el-dialog>
</template>

<script>
import _ from "lodash";
import MemberCenter from "../../components/Shared/MemberCenter/Member.vue";

import Note from "../ChargeMeContactBook/Note/Note.vue";
import ResponseLineBot from "../../components/ChargeMeEnterpriseGPT/ResponseLineBot.vue";
import QACenter from "../../components/ChargeMeEnterpriseGPT/QACenter/QACenter.vue";
import EditInputButton from "../../components//Common/CommonElement/EditInputButton.vue";
import EditButtonGroup from "../../components//Common/EditButtonGroup.vue";
import LineAutoReply from "../../components/ChargeMeEnterpriseGPT/AutoReply/LineAutoReply.vue";

export default {
	name: "Home",
	components: {
		ResponseLineBot,
		Note,
		MemberCenter,
		QACenter,
		EditInputButton,
		EditButtonGroup,
		LineAutoReply,
	},
	data() {
		return {
			showAllTableData: false,
			isAllSelected: true,
			showEscheat: false,
			selectedRows: [], // 儲存勾選的行
			calendarSelectItem: "",
			currentMonth: new Date().getMonth(), // 用於追蹤月份變化
			currentYear: new Date().getFullYear(), // 用於追蹤月份變化
			currentEquipment: "",
			data_calendar: [],
			items: [],
			selectedDate: new Date(),
			defaultDate: new Date(),
			newEvent: "",
			schedules: {},
			value: new Date(),
			rentalTableList: {
				currentPage: 1, // 當前頁碼
				pageSize: 5, // 每頁顯示的數據條數
			},
			rentalTable: {
				currentPage: 1, // 當前頁碼
				pageSize: 5, // 每頁顯示的數據條數
			},
			//搜尋
			searchItem: "",
			searchrentalTable: "",
			//歸回狀態
			statusOptions: [
				{
					value: "已歸還",
					label: "已歸還",
				},
				{
					value: "未歸還",
					label: "未歸還",
				},
			],
			//租用清單
			rentaldialogVisible: false,
			rentaldialogForm: {
				id: "",
				name: "",
				total: 0,
				stocklevel: 0,
				lentQuantity: 0,
				remainingQuantity: 0,
			},
			rentalTableData: [],
			//租用人清單
			rentalListdialogVisible: false,
			rentalListdialogForm: {
				id: null,
				renterName: "",
				renterCampsite: "",
				startDate: "",
				endDate: "",
				rentalCount: 0,
				status: "",
				remark: "",
				itemId: null,
				items: [{ itemId: "", rentalCount: 1 }],
			},
			rentalListTableData: [],
			checkedTasks: [false, false, false, false, false],
			activeName: "note",
			textarea2: "",
			isAllWebhookActive: true,
			promptData: {
				isEnbaled: false,
				task1: {
					desc: "",
					id: "1",
					name: "",
					tipWord: "",
					task: "",
				},
				task2: {
					desc: "",
					id: "2",
					name: "",
					tipWord: "",
					task: "",
				},
				task3: {
					desc: "",
					id: "3",
					name: "",
					tipWord: "",
					task: "",
				},
				task4: {
					desc: "",
					id: "4",
					name: "",
					tipWord: "",
					task: "",
				},
				task5: {
					desc: "",
					id: "5",
					name: "",
					tipWord: "",
					task: "",
				},
				promptDesc2: "",
				promptDesc3: "",
				promptId: "",
				user_id: "",
				user_name: "",
			},
			userSettings: [],
			channelSettings: {
				secret: "",
				accessToken: "",
			},
			channelSettingsBackup: {},
			isChannelSettingsClickedArray: [false, false, false],
			keywordSettings: {
				dataNotFound: "",
				searchNotFound: "",
				helpfulResponse: "",
				notHelpfulResponse: "",
				informResponse: "",
				informWithoutTextResponse: "",
				isNeedInformWithoutText: false,
			},
			keywordSettingsBackup: {},
			isKeywordSettingsClickedArray: [false, false, false, false, false, false],
			embedSettings: {
				embedUrl: "",
				isAllowAllOrigin: false,
				allowOriginUrls: [],
			},
			embedSettingsBackup: {},
		};
	},
	methods: {
		copyText() {
			navigator.clipboard
				.writeText(this.embedSettings.embedUrl)
				.then(() => {
					this.notify("success", "複製嵌入網址成功！");
				})
				.catch(() => {
					this.notify("error", "複製嵌入網址失敗！");
				});
		},
		async getEmbedSettings() {
			const vm = this;
			vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/enterprise/embed`)
				.then((res) => {
					vm.embedSettings = {
						embedUrl: res.data.embedUrl,
						isAllowAllOrigin: res.data.isAllowAllOrigin,
						allowOriginUrls: res.data.allowOriginUrls,
					};
					console.log(vm.embedSettings);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		embedAllowAllUrl() {
			const vm = this;
			let data = {
				IsActive: vm.embedSettings.isAllowAllOrigin,
			};
			vm.fetchAPI(
				"put",
				`${process.env.VUE_APP_URL_API}/enterprise/embed/all`,
				data
			)
				.then(() => {
					vm.notify("success", "資料更新成功！");
				})
				.catch((err) => {
					vm.notify("error", `資料更新失敗，${err.response.data.Msg}！`);
				});
		},
		embedEditCompleted() {
			const vm = this;
			let data = {
				AllowOriginUrls: vm.embedSettings.allowOriginUrls,
			};
			vm.fetchAPI(
				"put",
				`${process.env.VUE_APP_URL_API}/enterprise/embed`,
				data
			)
				.then(() => {
					vm.embedSettingsBackup = _.cloneDeep(vm.embedSettings);
					vm.notify("success", "資料更新成功！");
				})
				.catch((err) => {
					vm.$refs["embedSettings"].resetFields();
					vm.embedSettings = _.cloneDeep(vm.embedSettingsBackup);
					vm.notify("error", `資料更新失敗，${err.response.data.Msg}！`);
				});
		},
		tableRowStyle({ row }) {
			const selected = new Date(this.selectedDate);
			selected.setHours(0, 0, 0, 0);
			const rentDate = new Date(row.startDate);
			rentDate.setHours(0, 0, 0, 0);
			// 正確比較是否是同一天
			if (selected.getTime() === rentDate.getTime()) {
				return {
					fontSize: "14px",
					color: "rgb(53, 171, 85)",
				};
			}
			return {};
		},
		onDateClick(data) {
			this.showAllTableData = false;
			this.selectedDate = data.date; // 設置選中的日期
		},
		getFilteredOptions(index) {
			const selectedIds = this.rentalListdialogForm.items
				.map((item) => item.itemId)
				.filter((id) => id !== null);
			return this.rentalItemOption.filter(
				(option) =>
					!selectedIds.includes(option.value) ||
					this.rentalListdialogForm.items[index].itemId === option.value
			);
		},
		addItem() {
			this.rentalListdialogForm.items.push({ itemId: "", rentalCount: 1 });
		},
		toUTC(date) {
			return new Date(
				date.getTime() - date.getTimezoneOffset() * 60000
			).toISOString();
		},
		handleSelectionChange(val) {
			this.selectedRows = val;
			console.log(this.selectedRows);
		},
		setStatus(status) {
			console.log(this.selectedRows, status);
			this.selectedRows.forEach((x) => {
				this.rentalListdialogForm = x;
				this.rentalListdialogForm.status =
					status == false ? "未歸還" : "已歸還";
				this.saveItemRentalList();
			});
		},
		onDateChange(newDate) {
			const newMonth = new Date(newDate).getMonth();
			const newYear = new Date(newDate).getFullYear();
			if (newMonth !== this.currentMonth) {
				this.currentMonth = newMonth;
				this.currentYear = newYear;
				this.getCampAllEquipment();
				this.getRentalList();
			}
		},

		formatDate_calendar(dateTime) {
			// 確保日期格式統一為 yyyy-mm-dd
			const date = new Date(dateTime);
			return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
				2,
				"0"
			)}-${String(date.getDate()).padStart(2, "0")}`;
		},
		generateRemainingMap(v) {
			let vm = this;
			let res = "";
			// 使用 find 來優化查找
			const matchingItem = vm.data_calendar.find(
				(item) => vm.formatDate_calendar(item.Date) === v
			);

			if (matchingItem) {
				res = matchingItem.Remaining; // 找到的資料中的 Remaining 值
			}

			return res ? `${res}` : ""; // 如果找不到，返回剩餘: 0
		},
		async getCampAllEquipment() {
			let vm = this;
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/LineBot/GetCampAllEquipment`,
				{
					year: vm.currentYear,
					month: vm.currentMonth + 1,
					day: 0,
					equipment:
						vm.isAllSelected || vm.currentEquipment.label == "All"
							? ""
							: vm.currentEquipment.label,
					camperId: vm.currentUser().id,
				}
			).then((res) => {
				vm.data_calendar = res.data;
				console.log(res.data);
			});
		},

		highlightDate(date) {
			const specialDates = ["2025-01-01", "2025-01-15"];
			const formattedDate = date.toISOString().split("T")[0];
			return specialDates.includes(formattedDate)
				? { backgroundColor: "yellow", borderRadius: "50%" }
				: {};
		},
		handlePageChange_rentalTable(value) {
			this.rentalTable.currentPage = value;
		},
		handlePageChange_rentalTableList(value) {
			this.rentalTableList.currentPage = value;
		},
		// Channel & Keyword settings
		async getKeywordSettings() {
			const vm = this;
			vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/enterprise/keyword`)
				.then((res) => {
					vm.keywordSettings = {
						dataNotFound: res.data.dataNotFound,
						searchNotFound: res.data.searchNotFound,
						helpfulResponse: res.data.helpfulResponse,
						notHelpfulResponse: res.data.notHelpfulResponse,
						informResponse: res.data.informResponse,
						informWithoutTextResponse: res.data.informWithoutTextResponse,
						isNeedInformWithoutText: res.data.isNeedInformWithoutText,
					};
				})
				.catch((err) => {
					console.log(err);
				});
		},
		keywordEditCompleted(index) {
			const vm = this;
			let data = {
				DataNotFound: vm.keywordSettings.dataNotFound,
				SearchNotFound: vm.keywordSettings.searchNotFound,
				HelpfulResponse: vm.keywordSettings.helpfulResponse,
				NotHelpfulResponse: vm.keywordSettings.notHelpfulResponse,
				InformResponse: vm.keywordSettings.informResponse,
				InformWithoutTextResponse: vm.keywordSettings.informWithoutTextResponse,
				IsNeedInformWithoutText: vm.keywordSettings.isNeedInformWithoutText,
			};
			vm.fetchAPI(
				"put",
				`${process.env.VUE_APP_URL_API}/enterprise/keyword`,
				data
			)
				.then(() => {
					vm.keywordSettingsBackup = _.cloneDeep(vm.keywordSettings);
					vm.notify("success", "資料更新成功！");
				})
				.catch((err) => {
					vm.$refs["keywordSettings"].resetFields();
					vm.keywordSettings = _.cloneDeep(vm.keywordSettingsBackup);
					vm.notify("error", `資料更新失敗，${err.response.data.Msg}！`);
				})
				.finally(() => {
					vm.isKeywordSettingsClickedArray[index] = false;
				});
		},
		async getChannelSettings() {
			const vm = this;
			vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/enterprise/member`)
				.then((res) => {
					console.log(res.data);
					vm.channelSettings = {
						secret: res.data.lineBotChannelSecret,
						accessToken: res.data.lineBotChannelAccessToken,
					};
				})
				.catch((err) => {
					console.log(err);
				});
		},
		channelEditCompleted(index) {
			const vm = this;
			let data = {
				LineBotChannelSecret: vm.channelSettings.secret,
				LineBotChannelAccessToken: vm.channelSettings.accessToken,
			};
			vm.fetchAPI(
				"put",
				`${process.env.VUE_APP_URL_API}/enterprise/channel`,
				data
			)
				.then(() => {
					vm.channelSettingsBackup = _.cloneDeep(vm.channelSettings);
					vm.notify("success", "資料更新成功！");
				})
				.catch((err) => {
					console.log(err);
					vm.$refs["channelSettings"].resetFields();
					vm.channelSettings = _.cloneDeep(vm.channelSettingsBackup);
					vm.notify("error", `資料更新失敗，${err.response.data.Msg}！`);
				})
				.finally(() => {
					vm.isChannelSettingsClickedArray[index] = false;
				});
		},
		changeToEdit(index, type) {
			if (type === "channel") {
				if (
					!this.isChannelSettingsClickedArray.every((item) => item === false)
				) {
					this.channelSettings = _.cloneDeep(this.channelSettingsBackup);
				}
				this.channelSettingsBackup = _.cloneDeep(this.channelSettings);
				this.isChannelSettingsClickedArray = [false, false, false];
				this.isChannelSettingsClickedArray[index] = true;
				this.$refs[`autoFocus${index}_channel`].focus();
			} else if (type === "keyword") {
				if (
					!this.isKeywordSettingsClickedArray.every((item) => item === false)
				) {
					this.keywordSettings = _.cloneDeep(this.keywordSettingsBackup);
				}
				this.keywordSettingsBackup = _.cloneDeep(this.keywordSettings);
				this.isKeywordSettingsClickedArray = [
					false,
					false,
					false,
					false,
					false,
					false,
				];
				this.isKeywordSettingsClickedArray[index] = true;
				this.$refs[`autoFocus${index}_keyword`].focus();
			}
		},
		changeToOrigin(index, type) {
			if (type === "channel") {
				this.$refs["channelSettings"].resetFields();
				this.channelSettings = _.cloneDeep(this.channelSettingsBackup);
				this.isChannelSettingsClickedArray[index] = false;
			} else if (type === "keyword") {
				this.$refs["keywordSettings"].resetFields();
				this.keywordSettings = _.cloneDeep(this.keywordSettingsBackup);
				this.isKeywordSettingsClickedArray[index] = false;
			}
		},
		//
		handleTabClick(tab) {
			if (tab.paneName == "memberCenter") {
				// 通过 ref 调用子组件的方法
				this.$refs.memberCenterRef.getMember();
			}
		},
		async deleteItemRental(obj) {
			let vm = this;
			this.$confirm("確定要刪除嗎?", "提示", {
				confirmButtonText: "確定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					obj.id;
					vm.fetchAPI(
						"delete",
						`${process.env.VUE_APP_URL_API}/CampGrabber/${obj.id}`
					).then((res) => {
						console.log(res);
						this.$message({
							type: "success",
							message: "刪除成功!",
						});
						vm.getItemRentals();
						vm.getRentalList();
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消刪除",
					});
				});
		},
		async deleteRentalList(obj) {
			let vm = this;
			this.$confirm("確定要刪除嗎?", "提示", {
				confirmButtonText: "確定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					obj.id;
					vm.fetchAPI(
						"delete",
						`${process.env.VUE_APP_URL_API}/CampGrabber/RentalList/${obj.id}`
					).then((res) => {
						console.log(res);
						this.$message({
							type: "success",
							message: "刪除成功!",
						});
						vm.getItemRentals();
						vm.getRentalList();
						vm.getCampAllEquipment();
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消刪除",
					});
				});
		},

		formatDate(date) {
			if (!date) return "";
			const d = new Date(date);
			const year = d.getFullYear();
			const month = String(d.getMonth() + 1).padStart(2, "0"); // 月份是從0開始的
			const day = String(d.getDate()).padStart(2, "0");
			return `${year}-${month}-${day}`;
		},
		async getRentalList() {
			let vm = this;
			await vm
				.fetchAPI(
					"get",
					`${process.env.VUE_APP_URL_API}/CampGrabber/RentalList?year=${this.currentYear}&month=${this.currentMonth}`
				)
				.then((res) => {
					vm.rentalListTableData = res.data;
				});
		},
		async saveItemRentalList() {
			let vm = this;
			//必填物品名稱
			if (vm.rentalListdialogForm.renterName == "") {
				this.$message.error("請輸入借用人姓名！");
				return;
			}

			if (vm.rentalListdialogForm.startDate == "") {
				this.$message.error("請輸入租借日期");
				return;
			}
			if (vm.rentalListdialogForm.endDate == "") {
				this.$message.error("請輸入歸還日期");
				return;
			}

			if (vm.rentalListdialogForm.endDate < vm.rentalListdialogForm.startDate) {
				this.$message.error("歸還日期不得小於租借日期");
				return;
			}

			if (vm.rentalListdialogForm.status == "") {
				this.$message.error("請選擇歸還狀態");
				return;
			}

			if (vm.rentalListdialogForm.id == null) {
				// 新增時檢查 items 的每個項目
				for (const item of vm.rentalListdialogForm.items) {
					if (item.itemId == null) {
						this.$message.error("請選擇項目名稱");
						return;
					}
					if (item.rentalCount == null || item.rentalCount <= 0) {
						this.$message.error("請輸入有效的租借數量");
						return;
					}

					if (vm.rentalListdialogForm.status == "未歸還") {
						// 取得起始日期和結束日期
						const startDate = new Date(vm.rentalListdialogForm.startDate);
						const endDate = new Date(vm.rentalListdialogForm.endDate);
						const dateRange = [];
						let currentDate = new Date(startDate);

						// 填充日期範圍
						while (currentDate <= endDate) {
							dateRange.push(currentDate.toISOString().split("T")[0]); // 格式化為 'yyyy-mm-dd'
							currentDate.setDate(currentDate.getDate() + 1); // 增加一天
						}

						// 檢查每個日期的庫存
						for (const date of dateRange) {
							const selectedItem = vm.data_calendar.find((calendarItem) => {
								const itemDate = new Date(calendarItem.Date);
								const itemDateStr = itemDate.toISOString().split("T")[0];
								return (
									itemDateStr === date && calendarItem.ItemId === item.itemId
								);
							});

							// 如果找到該日期並且庫存不足，則顯示錯誤訊息
							if (selectedItem && selectedItem.Remaining < item.rentalCount) {
								this.$message.error(
									`日期 ${date} 項目 "${selectedItem.ItemName}" 剩餘數量不足，請調整租借數量！`
								);
								return;
							}
						}
					}
				}
			} else {
				if (vm.rentalListdialogForm.itemId == null) {
					this.$message.error("請選擇項目名稱");
					return;
				}
				if (vm.rentalListdialogForm.status == "未歸還") {
					// 取得起始日期和結束日期
					const startDate = new Date(vm.rentalListdialogForm.startDate);
					const endDate = new Date(vm.rentalListdialogForm.endDate);
					// 創建一個日期範圍
					const dateRange = [];
					let currentDate = new Date(startDate);

					// 填充日期範圍
					while (currentDate <= endDate) {
						dateRange.push(currentDate.toISOString().split("T")[0]); // 格式化為 'yyyy-mm-dd'
						currentDate.setDate(currentDate.getDate() + 1); // 增加一天
					}

					// 檢查每個日期的庫存
					for (const date of dateRange) {
						const selectedItem = vm.data_calendar.find((item) => {
							const itemDate = new Date(item.Date);
							const itemDateStr = itemDate.toISOString().split("T")[0];
							return itemDateStr === date;
						});

						// 如果找到該日期並且庫存不足，則顯示錯誤訊息
						if (
							selectedItem &&
							selectedItem.Remaining < vm.rentalListdialogForm.rentalCount
						) {
							this.$message.error(
								`日期 ${date} 剩餘數量不足，請調整租借數量！`
							);
							return;
						}
					}
				}
			}

			// 設置起始時間為當天 00:00
			const startDate = new Date(vm.rentalListdialogForm.startDate);
			startDate.setHours(0, 0, 0, 0); // 設置時間為 00:00:00.000

			// 設置結束時間為當天 23:59 或隔天 00:00
			const endDate = new Date(vm.rentalListdialogForm.endDate);
			endDate.setHours(0, 0, 0, 0); // 設置時間為 23:59:59.999

			// 轉換為 UTC 格式後存入表單
			vm.rentalListdialogForm.startDate = this.toUTC(startDate);
			vm.rentalListdialogForm.endDate = this.toUTC(endDate);
			await vm
				.fetchAPI(
					"post",
					`${process.env.VUE_APP_URL_API}/CampGrabber/RentalList`,
					vm.rentalListdialogForm
				)
				.then((res) => {
					console.log(res.data);
					vm.rentalListdialogVisible = false;
					vm.getItemRentals();
					vm.getRentalList();
					vm.getCampAllEquipment();
				});
		},
		async getItemRentals() {
			let vm = this;
			await vm
				.fetchAPI(
					"get",
					`${process.env.VUE_APP_URL_API}/CampGrabber`,
					vm.rentaldialogForm
				)
				.then((res) => {
					vm.rentalTableData = res.data;
				});
		},
		async saveItemRental() {
			let vm = this;
			//必填物品名稱
			if (vm.rentaldialogForm.name == "") {
				this.$message.error("請輸入物品名稱！");
				return;
			}
			if (vm.rentaldialogForm.id == null) {
				//判斷名稱重複
				const isDuplicate = vm.rentalTableData.some(
					(item) => item.name === vm.rentaldialogForm.name
				);
				if (isDuplicate) {
					this.$message.error("物品名稱重複，請重新輸入！");
					return;
				}
			}

			//判斷總數量不低於零
			if (vm.rentaldialogForm.total <= 0) {
				this.$message.error("總數量不能小於0或等於0！");
				return;
			}

			//判斷水位不低於總數量
			if (vm.rentaldialogForm.stocklevel > vm.rentaldialogForm.total) {
				this.$message.error("庫存水位不能超過總數量！");
				return;
			}

			await vm
				.fetchAPI(
					"post",
					`${process.env.VUE_APP_URL_API}/CampGrabber`,
					vm.rentaldialogForm
				)
				.then((res) => {
					console.log(res.data);
					vm.rentaldialogVisible = false;
					vm.getItemRentals();
				});
		},
		openRentalSetting(obj) {
			this.rentaldialogVisible = true;
			if (obj) {
				this.rentaldialogForm = obj;
			} else {
				this.rentaldialogForm = {
					id: null,
					name: "",
					total: 0,
					stocklevel: 0,
					lentQuantity: 0,
					remainingQuantity: 0,
				};
			}
		},
		openRentaListSetting(obj, date) {
			this.rentalListdialogVisible = true;
			if (obj) {
				this.rentalListdialogForm = obj;
			} else {
				this.rentalListdialogForm = {
					id: null,
					renterName: "",
					renterCampsite: "",
					startDate: new Date(date.day),
					endDate: new Date(date.day),
					rentalCount: 0,
					status: "未歸還",
					remark: "",
					items: [{ itemId: "", rentalCount: 1 }],
					itemId: this.currentEquipment.value,
				};
			}
		},
		async getBasicSetting() {
			let vm = this;
			await vm
				.fetchAPI(
					"get",
					`${process.env.VUE_APP_URL_API}/LineBot/GetCampBasicSetting`
				)
				.then((res) => {
					var data = res.data;
					console.log(data);
					vm.isAllWebhookActive = data.find(
						(x) => x.isSettingAll
					).isWebhookActive;
					vm.userSettings = data.filter((x) => !x.isSettingAll);
					console.log(vm.userSettings);
				});
		},
		async updateBasicSetting(item) {
			let vm = this;
			let data = {
				MemberId: item.memberId,
				IsSettingAll: false,
				IsWebhookActive: item.isWebhookActive,
			};
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/LineBot/EditCampBasicSetting`,
				data
			).then(() => {
				vm.notify("success", `設定成功！`);
			});
		},
		async updateAllBasicSetting() {
			let vm = this;
			let data = {
				IsSettingAll: true,
				IsWebhookActive: vm.isAllWebhookActive,
			};
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/LineBot/EditCampBasicSetting`,
				data
			).then(() => {
				vm.notify("success", `設定成功！`);
			});
		},
		updateTask(index) {
			const taskName = `task${index + 1}`;
			this.promptData[taskName].task = this.checkedTasks[index]
				? "ReturnNow"
				: "";
		},
		updateTask_2(index) {
			const taskName = `task${index + 1}`;
			console.log(this.promptData[taskName].task);
			this.checkedTasks[index] =
				this.promptData[taskName].task == "ReturnNow" ? true : false;
		},
		callChildMethod() {
			this.$refs.childNote.initNoteData();
		},
		callChildMethod_childResponseLineBot() {
			this.$refs.childResponseLineBot.initNoteData();
		},
		async saveData() {
			let vm = this;
			let promptDataCopy = JSON.parse(JSON.stringify(vm.promptData));
			Object.keys(promptDataCopy).forEach((key) => {
				if (key.startsWith("task")) {
					promptDataCopy[key].name = promptDataCopy.user_name;
					promptDataCopy[key].id = key.replace("task", "");
				}
			});
			await vm
				.fetchAPI(
					"post",
					`${process.env.VUE_APP_URL_API}/GPTPrompt/Save`,
					promptDataCopy
				)
				.then((res) => {
					console.log(res);
					vm.notify("success", `設定成功！`);
					vm.getData();
				});
		},
		async getData() {
			let vm = this;
			await vm
				.fetchAPI("get", `${process.env.VUE_APP_URL_API}/GPTPrompt/Get`)
				.then((res) => {
					console.log(res);
					vm.promptData = res.data.response;
				});
			this.checkedTasks.forEach((isChecked, index) => {
				this.updateTask_2(index);
			});
		},
	},
	watch: {
		async activeName(newVal) {
			if (newVal == "note2") {
				this.$router.push({ name: "ResponseLineBotGPT" });
				await this.$refs.childResponseLineBot.childChatInit();
			} else if (newVal == "note1") {
				await this.getData();
			} else if (newVal == "basicSetting") {
				await this.getChannelSettings();
				await this.getBasicSetting();
				await this.getKeywordSettings();
				await this.getEmbedSettings();
			} else if (newVal == "ItemRental") {
				await this.getItemRentals();
				await this.getRentalList();
				if (this.rentalItemOption.length > 0) {
					this.currentEquipment = this.rentalItemOption[0];
					this.calendarSelectItem = this.rentalItemOption[0].value;
					await this.getCampAllEquipment();
				}
			} else {
				this.callChildMethod();
				this.$router.push({ name: "EnterpriseGPTHome" });
			}
		},
	},
	computed: {
		rentalListTableDataFiltered() {
			let result = this.rentalListTableData.filter((row) => {
				if (!this.searchItem) return true; // 沒有輸入搜尋內容時，返回所有數據
				const searchLower = this.searchItem.toLowerCase();
				// // 遍歷每個屬性，檢查是否包含搜尋關鍵字
				return Object.values(row).some(
					(value) =>
						value && value.toString().toLowerCase().includes(searchLower)
				);
			});
			result = result.filter((x) => {
				let currentEquipmentV = this.currentEquipment.value || "";
				if (currentEquipmentV == "") {
					if (!this.showEscheat) {
						return x.status == "未歸還";
					} else {
						return true;
					}
				} else {
					if (!this.showEscheat) {
						return x.itemId == currentEquipmentV && x.status == "未歸還";
					} else {
						return x.itemId == currentEquipmentV;
					}
				}
			});

			// 篩選日期範圍內的設備
			if (this.isAllSelected == false && !this.showAllTableData) {
				const selected = new Date(this.selectedDate);
				result = result.filter((row) => {
					const rentDate = new Date(row.startDate);
					const returnDate = new Date(row.endDate);
					// 將 returnDate 設定為當天 23:59:59
					returnDate.setHours(23, 59, 59, 999);
					return selected >= rentDate && selected <= returnDate;
				});
			}

			const start =
				(this.rentalTableList.currentPage - 1) * this.rentalTableList.pageSize;
			const end =
				this.rentalTableList.currentPage * this.rentalTableList.pageSize;

			return result.slice(start, end);
		},
		rentalTableDataFiltered() {
			let result = this.rentalTableData.filter((row) => {
				if (!this.searchrentalTable) return true; // 沒有輸入搜尋內容時，返回所有數據
				const searchLower = this.searchrentalTable.toLowerCase();
				// 遍歷每個屬性，檢查是否包含搜尋關鍵字
				return Object.values(row).some(
					(value) =>
						value && value.toString().toLowerCase().includes(searchLower)
				);
			});
			const start =
				(this.rentalTable.currentPage - 1) * this.rentalTable.pageSize;
			const end = this.rentalTable.currentPage * this.rentalTable.pageSize;
			return result.slice(start, end);
		},
		rentalItemOption() {
			let result = this.rentalTableData.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			result.unshift({
				value: "",
				label: "All",
			});
			return result;
		},
		tabMargin() {
			return this.activeName === "note" && !this.$store.getters.mobileSize;
		},
	},
	async mounted() {
		const path = this.$route.path;
		if (
			path.includes("ResponseLineBot") ||
			this.$store.state.isOpenCampLineResponse
		) {
			this.activeName = "note2";
		}
	},
};
</script>

<style lang="scss" scoped>
.tabMargin {
	margin-left: 250px;
}

.labelcustom {
	font-size: 0.85rem;
	border-bottom: 2px solid rgba(0, 4, 255, 0.15);
	padding-bottom: 4px;
	color: black;
	margin-bottom: 8px;
	margin-top: 8px;
	font-weight: 600;
	text-align: start;
}

::v-deep .el-tabs__header {
	margin: 0 !important;
}

.box-card {
	margin-bottom: 0.5rem;
}

.userList {
	max-height: 800px;
	overflow-y: auto;
}

.basicSetting {
	padding: 1rem 2rem 0;

	::v-deep .el-tabs {
		height: 100%;

		.content {
			text-align: start;
			margin-top: 1rem;

			.dangerText {
				margin-bottom: 1rem;
				color: rgb(225, 121, 121);
				font-weight: bold;
				line-height: 1.5rem;
			}

			.normalText {
				// margin-bottom: 1rem;
				font-weight: bold;
				line-height: 1.5rem;
			}
		}
	}
}

:deep .el-form-item {
	// border-bottom: 1px solid rgb(183, 183, 183);
	.el-form-item__content {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		justify-content: space-between;

		.el-input.disActive {
			.el-input__inner {
				cursor: default;
			}

			.el-input__wrapper {
				box-shadow: none;
			}
		}

		.edit_group {
			flex-grow: 1;
			margin-left: 1rem;

			.editButtonGroup {
				width: 8.5rem;
			}
		}
	}
}

:deep .el-form-item.custom {
	.el-form-item__content {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column !important;
		align-items: flex-start;
		justify-content: space-between;

		.el-input.disActive {
			.el-input__inner {
				cursor: default;
			}

			.el-input__wrapper {
				box-shadow: none;
			}
		}

		.edit_group {
			flex-grow: 1;
			margin-left: 1rem;

			.editButtonGroup {
				width: 8.5rem;
			}
		}
	}
}

.edit_group {
	display: flex;
	justify-content: flex-end;
}

.hide-calendar ::v-deep(.el-calendar__body) {
	display: none !important;
}

@media screen and (min-width: 768px) {
	::v-deep .el-tabs {
		.el-tabs__content {
			padding: 0 2rem;
		}

		.content {
			margin-top: 0 !important;
		}
	}
}
</style>
