import authHeader from "../services/auth-header";
import store from "../store/index";
import AuthService from "../services/auth.service";
import axios from "axios";
import { ElNotification } from "element-plus";
import { ref } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { defineStore } from "pinia";

export const useUtils = defineStore("utils", () => {
	const route = useRoute();
	const enumOptions = {
		notify: {
			success: "成功",
			info: "消息",
			warning: "警告",
			error: "失敗",
		},
	};

	const fetchAPI = async (method, url, request = {}, isLoading = true) => {
		if (isLoading && store.state.classRoomTabActiveName != "second") {
			store.commit("setIsLoading", true);
		}
		return await axios({
			method: method,
			url: url,
			headers: authHeader(),
			data: request,
		})
			.then((response) => {
				if (isLoading) {
					store.commit("setIsLoading", false);
				}
				return response;
			})
			.catch((err) => {
				if (isLoading) {
					store.commit("setIsLoading", false);
				}
				if (err.response.data.Status == 2000) this.logoutCommon();
				return Promise.reject(err);
			});
	};
	const turnBirthdayType = (birthday) => {
		if (birthday === "") return null;
		else if (birthday === null) return "";
		else return birthday;
	};
	const notify = (
		type,
		message,
		htmlString = false,
		duration = 1500,
		position = "top-right"
	) => {
		const title = ref("");

		title.value = enumOptions.notify[type] || "失敗";

		ElNotification({
			title: title,
			message: message,
			type: type,
			duration: duration,
			position: position,
			dangerouslyUseHTMLString: htmlString,
		});
	};
	const logoutCommon = async () => {
		console.log("logoutCommon", router);

		await router.isReady().then(() => {
			store.dispatch("auth/logout");
			AuthService.logout();
			if (route.path.toLowerCase().includes("enterprise")) {
				router.push("/EnterpriseLogin");
			} else {
				router.push("/login");
			}
			store.state.hub.connection.stop();
		}); // 確保路由已準備好
	};
	return {
		fetchAPI,
		turnBirthdayType,
		notify,
		logoutCommon,
	};
});
