import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useUtils } from '@/utils/index'
const utils = useUtils()
export const useClientCenterStore = defineStore('clientCenterStore', () => {
    const actionNum = ref(0)
    const selectedUser = ref('')
    const tags = ref([])
    const filterTags = ref([])
    const filterUsers = ref('')
    const userLists = ref([])

    const activeTagUser = (user) => {
        if (selectedUser.value === user) {
            return
        }
        if (selectedUser.value !== '') {
            selectedUser.value.clicked = false
        }
        selectedUser.value = user
        selectedUser.value.clicked = true
    }
    const unActiveTagUser = () => {
        selectedUser.value.clicked = false
        selectedUser.value = ''
    }
    const getUserList = async () => {
        await utils.fetchAPI("GET", `${process.env.VUE_APP_URL_API}/LineBot/GetCamperAllUsers`)
            .then((res) => {
                console.log(res);
                userLists.value = res.data.map((user) => {
                    return {
                        ...user,
                        clicked: false,
                    }
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const getUserTagList = async () => {
        await utils.fetchAPI("GET", `${process.env.VUE_APP_URL_API}/Enterprise/IndividualUserTag`)
            .then((res) => {
                console.log(res);
                tags.value = res.data
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const getUserTag = async (userId) => {
        await utils.fetchAPI("GET", `${process.env.VUE_APP_URL_API}/Enterprise/IndividualUser/${userId}`)
            .then((res) => {
                console.log(res);
                selectedUser.value.tags = res.data.tags || []
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return {
        actionNum,
        selectedUser,
        tags,
        filterTags,
        filterUsers,
        userLists,
        activeTagUser,
        unActiveTagUser,
        getUserList,
        getUserTagList,
        getUserTag
    }
})