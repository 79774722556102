<script setup>
import { useClientCenterStore } from '@/store/clientCenterStore'
import { useI18n } from 'vue-i18n'
import { useUtils } from '@/utils/index'
import { storeToRefs } from 'pinia'
import { ref, h } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'

const utils = useUtils()
const clientCenterStore = useClientCenterStore()
const { tags } = storeToRefs(clientCenterStore)
const { t } = useI18n()
const props = defineProps({
    visible: Boolean,
});
const emits = defineEmits(['close']);

const renameId = ref(null);

const renameTag = (id) => {
    renameId.value = id;
};
const saveRename = async (id) => {
    const input = document.getElementById(`tagId_${id}`);
    if (input) {
        await utils.fetchAPI("put", `${process.env.VUE_APP_URL_API}/Enterprise/IndividualUserTag`, {
            id: id,
            name: input.value,
        });
        await clientCenterStore.getUserTagList();
    }
    ElMessage.success(t('QACenter.TagSet.Dialog.EditingCompleted'));
    renameId.value = null;
};
const getTagUser = async (tagid) => {
    return await utils.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Enterprise/IndividualMemberUserTag/tag/${tagid}`).then(res => {
        return res.data || [];
    });
};
const deleteConfirm = async (id) => {
    const tagUsers = await getTagUser(id);
    if (tagUsers.length > 0) {
        const strUsers = tagUsers.map(x => x.userName).join('、');
        ElMessageBox.confirm(h('p', null, [h('p', null, t('QACenter.TagSet.Dialog.DeleteAlert')), h('p', null, `${strUsers}`), h('p', null, t('QACenter.TagSet.Dialog.DeleteAlert2'))]), t('QACenter.TagSet.Dialog.Alert'), {
            confirmButtonText: t('QACenter.TagSet.Dialog.Confirm'),
            cancelButtonText: t('QACenter.TagSet.Dialog.Cancel'),
            type: 'warning',
        }).then(async () => {
            for (const user of tagUsers) {
                await utils.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/Enterprise/IndividualMemberUserTag/${user.userId}/${user.tagId}`);
            }
            await utils.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/Enterprise/IndividualUserTag/${id}`);
            await clientCenterStore.getUserTagList();
            ElMessage.success(t('QACenter.TagSet.Dialog.DeletingCompleted'));
        }).catch(() => {
            console.log('取消刪除');
        });
    } else {
        ElMessageBox.confirm(t('QACenter.TagSet.Dialog.DeleteAlert2'), t('QACenter.TagSet.Dialog.Alert'), {
            confirmButtonText: t('QACenter.TagSet.Dialog.Confirm'),
            cancelButtonText: t('QACenter.TagSet.Dialog.Cancel'),
            type: 'warning',
        }).then(async () => {
            await utils.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/Enterprise/IndividualUserTag/${id}`);
            await clientCenterStore.getUserTagList();
            ElMessage.success(t('QACenter.TagSet.Dialog.DeletingCompleted'));
        }).catch(() => {
            console.log('取消刪除');
        });
    }
};
</script>

<template>
    <el-dialog v-model="props.visible"
               :title="$t('QACenter.TagSet.Edit')"
               width="300px"
               center
               @close="emits('close')">
        <div v-for="i in tags"
             :key="i"
             class="d-flex mb-3 editTagOption"
             :data-tagId="i.id"
             style="font-size: medium"
             @click="renameTag(i.id)">
            <div class="d-flex align-items-center">
                <i v-if="renameId == i.id"
                   class="trashTag far fa-trash-alt me-2"
                   @click.stop.prevent="deleteConfirm(i.id)"></i>
                <i v-else
                   class="fa-light fa-tag me-2"></i>
                <input class="renameInput"
                       :id="'tagId_' + i.id"
                       v-if="renameId == i.id"
                       type="text"
                       :value="i.name" />
                <div v-else
                     class="d-inline-block"
                     style="width: 200px">
                    {{ i.name }}
                </div>
                <el-button v-if="renameId == i.id"
                           circle
                           @click.stop.prevent="saveRename(i.id)">
                    <i style="width: 14px; height: 14px"
                       class="fal fa-check"></i>
                </el-button>
                <el-button v-else
                           circle>
                    <i style="width: 14px; height: 14px"
                       class="fal fa-pen"></i>
                </el-button>
            </div>
        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary"
                           @click="
                            emits('close')
                            ">{{ $t('QACenter.TagSet.Save') }}</el-button>
            </span>
        </template>
    </el-dialog>
</template>
<style scoped>
.trashTag {
    cursor: pointer;
}

.renameInput {
    border: none;
    width: 200px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
</style>